import React from 'react';

import Header from './components/header';
import MainSection from './components/main';



import { Navbar, Nav, NavItem, NavDropdown, Glyphicon } from "react-bootstrap";


import './App.css';
import { Web3ReactProvider } from '@web3-react/core';
import Web3 from 'web3';




const getLibrary = (provider) => {
  return new Web3(provider);
}

const App = () => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <div>
        <Header />
        <MainSection />
        </div>
       
    
    </Web3ReactProvider>
  );
}

export default App;
