import React from "react";
import Logo from "../../images/axt_logo.ico";
import "./style.css";
import { useWeb3React } from "@web3-react/core";
import { Injected } from "../../Helpers/Injected";

const Header = () => {
  
  const { active, account, library, connector, activate, deactivate } = useWeb3React();

  const connect = async () => {
   try {
     if(!account){
       await activate(Injected);
     } else {
       deactivate()
     }
   } catch (error) {
     console.log('error', error)
   }
  }


  return (
    
    <div className="header">

   <a href="https://axiomaholding.io/">    <img src={Logo} alt="logo"  style={{
          width: 100,
          height: 100 ,
        }} /></a>
  
          <nav class="nav nav-pills nav-fill">
        <a class="nav-item nav-link" href="https://axiomaholding.io/">MAIN</a>
        <a class="nav-item nav-link" href="http://axiomaholding.io/app">PRESALE</a>
        <a class="nav-item nav-link" href="https://axiomaholding.io/replica/AXIOMA">JOIN</a>
        <a class="nav-item nav-link" href="https://nft.axiomainvestments.io/">NFT</a>
      </nav>
      <div>
        <button onClick={connect}>
          {
            active ? `${account.substring(0, 4)}...${account.substring(38)}` :"Connect"
          }
        </button>
       
        
      </div>
    </div>
  );
};

export default Header;
