import React, { useEffect, useState } from "react";
import "./style.css";

import BNB from "../../images/bnb.png";
import logo from "../../images/axt.ico";
import { useWeb3React } from "@web3-react/core";
import { Injected } from "../../Helpers/Injected";
import Abi from "../../Helpers/abi.json";
import Web3 from "web3";


const isValid = (regex) => (input) => regex.test(input);
const numberRegex = /^\d*\.?\d*$/;
const isValidNumber = isValid(numberRegex);

const MainSection = () => {
  const { active, account, library, connector, activate, diactivate } =
    useWeb3React();

  const cAddress = "0x2C25aEe99ED08A61e7407A5674BC2d1A72B5D8E3";

  const [enable, setEnable] = useState(false);
  const [progressBalance, setProgressBalance] = useState(0);
  const gartVal = 300;
  const [data, setData] = useState({
    bnb: "",
    gart: "",
  });

  const processBuy = async () => {
    if (!data.bnb || !data.gart) {
      alert("Please enter correct value.");
      return;
    }
    setEnable(true);

    const contract = await new library.eth.Contract(Abi, cAddress);
    console.log("account", account);

    let bnbValue = await library.utils.toWei(data.bnb, "ether");
    await contract.methods
      .buyToken()
      .send({ from: account, value: bnbValue })
      .on("error", function (error) {
        setEnable(false);
        alert(error.message);
      })
      .then(function (receipt) {
        setEnable(false);
        alert(`${data.gart} AXT has been sent to your wallet.`);
        setData({ bnb: "", gart: "" });
      });
  };

  const buyToken = async () => {
    if (active) {
      processBuy();
    } else {
      await activate(Injected);
    }
  };

  useEffect(() => {
    getBalance();
  }, []);

  const getBalance = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      const balance = await window.web3.eth.getBalance(
        "0x3c20Bb2fd6dAbfF48ABEcf0D66B399902a3aDd77"
      );
      const balance_Eth = window.web3.utils.fromWei(balance, "ether");
      // console.log(balance_Eth);
      setProgressBalance(balance_Eth);
    }
  };

  const progress = (progressBalance / 50) * 100;
  const width =
    progress > 0
      ? Math.floor(progress) + 1 >= 100
        ? 100
        : Math.floor(progress) + 1
      : 0;

  return (
    <div className="main-section">


    
      <p className="headings">BUY NOW AND BE THE SHARE HOLDER</p>
      <p className="headings">Connect Bsc Mainnet</p>
      <p className="headings">LIMITED OFFER</p>
      <div className="main-section-form">
        <p>1 BNB = {gartVal} AXT</p>
        <p className="mgtp">Pay with</p>
        <div className="form-group">
          <input
            type="text"
            value={data.bnb}
            onChange={(e) => {
              const val = e.target.value
                .split("")
                .filter((el) => isValidNumber(el))
                .join("");
              setData({
                ...data,
                bnb: val,
                gart: val * gartVal,
              });
            }}
          />
          <div>
            <img src={BNB} alt="snk" style={{ marginRight: "0.1px" }} />
            <p className="tokenname">BNB</p>
          </div>
        </div>
        <p className="mgtp">You will get</p>

        <div className="form-group">
          <input
            type="text"
            value={data.gart}
            onChange={(e) => {
              const val = e.target.value
                .split("")
                .filter((el) => isValidNumber(el))
                .join("");
              setData({
                ...data,
                gart: val,
                bmb: val / gartVal,
              });
            }}
          />
          <div>
            <img src={logo} alt="snk" />
            <p className="tokenname">AXT</p>
          </div>
        </div>
        <div style={{ textAlign: "center", margin: "0.5em 0" }}>
          <a
            href="www.google.com"
            style={{ color: "#000000" }}
            className="bold"
          ></a>
        </div>
        <div style={{ textAlign: "center", margin: "0.5em 0" }}>
          <button className="buy" onClick={buyToken} enable>
            {/* <button className="buy" onClick={buyToken} enable> */}
            {account ? "Buy" : "Connect"}
          </button>
        </div>

        <div className="smart">
          <i class="fa fa-lock" aria-hidden="true"></i>
          <p>100% Secure smart contract</p>
        </div>
      </div>
      <div className="progress-parent">
        <div className="progress-btm">
          <p>1 BNB</p>
          <p>Presale Tracker </p>
          <p>50 BNB</p>
        </div>
        <div className="progres-bar-wrapper">
          <div className="progress-inner" style={{ width: `${width}%` }}></div>
        </div>
      </div>

      <div className="link">
        <a href="https://bscscan.com/token/0xb6cf5b77b92a722bf34f6f5d6b1fe4700908935e">
          AXT token smart contract
        </a>

      </div>

      


    </div>


  );
};

export default MainSection;
